
const UrlManager = require("./UrlManager.js");
const Book = require("./Book.js");
const Auth = require("./Auth.js");
const enhanceTextarea = require("./enhanceTextarea.js");


const sitePages = {
  "": "#books/1STFV/getting-started/introduction",
};


$(function () {
  const urlManager = new UrlManager();

  function router(listBooks, listPages, showPage) {
    let book;

    function handleHashChange() {
      window.scrollTo(0,0);

      let url = document.location.hash;

      if (sitePages[url]) {
        url = sitePages[url];
      }
      var parts = urlManager.getUrlParts(url);

      if (parts.command !== "books") {
        document.location.href = "#books";
        return;
      }

      const handleBook = () => {
        if (parts.slug) {
          showPage(book, parts.slug);
        } else {
          listPages(book);
        }
      };

      if (parts.id) {
        if (book && book.loaded && book.id === parts.id) {
          handleBook();
          return;
        }
        book = new Book(parts.id, parts.book);
        book.load().then(handleBook);
      } else {
        listBooks();
      }


    }

    restoreScrollPositionOnBackButton();

    window.onhashchange = handleHashChange;
    handleHashChange();
  }

  let scrollPos = {x:window.scrollX, y:window.scrollY};

  function restoreScrollPositionOnBackButton() {
    $("body").on("click","a",() => {
      history.replaceState({x:window.scrollX, y:window.scrollY}, null, document.location.href);
      return true;
    });

    window.onpopstate = (e) => {
      scrollPos = e.state || {x:0, y:0};
    };
  }


  function addBook() {
    var title = prompt("What's the title of your book?");
    if (title) {
      var book = new Book(null, title);
      book.save(auth.currentUser()).then(() => {
        console.log("Saved "+book.json.id);
        document.location.href = urlManager.fromBookAndPage(book,"start");
      });
    }
  }

  function editPage(book, pageName) {
    $(".cs-pageTitle").text(book.getPageHeader(pageName));

    $(".cs-saveButton").unbind().click(() => {
      savePage(book, pageName);
      return false;
    });
    $(".cs-cancelButton").unbind().click(() => {
      if (book.getPageText(pageName)) {
        showPage(book, pageName);
      } else {
        window.history.back();
      }
      return false;
    });


    let text = book.getPageText(pageName);

    const textarea = $(".cs-pageTextarea");

    textarea.val(text);
    showPanel(".cs-editPanel");

    enhanceTextarea(textarea, () => savePage(book, pageName));
    textarea.focus();
  }

  function redirectIfNeeded(book, pageName) {
    if (sitePages[document.location.hash]) {
      return false;
    }
    const cannonicalUrl = urlManager.fromBookAndPage(book, pageName);
    if (!document.location.href.endsWith(cannonicalUrl)) {
      console.log("Redirecting to ",cannonicalUrl);
      return document.location.assign(cannonicalUrl);
    }
  }

  function showPanel(className) {
    $(".cs-page").show();
    $(".cs-panel").hide();
    $(className).show();
    window.scrollTo(scrollPos.x, scrollPos.y);
  }


  function showPage(book, pageName) {
    redirectIfNeeded(book, pageName);

    var html = book.getPageHtml(pageName);
    if (!html) {
      editPage(book, pageName);
      return;
    }

    let tocText = book.name;
    if (pageName === "start") {
      tocText = "Table of Contents";
    }
    $(".cs-TOCLink").text(tocText).attr("href",urlManager.fromBookAndPage(book));
    $(".cs-pageTitle").text(book.getPageHeader(pageName));
    $(".cs-pageText").html(html);
    $(".cs-editButton").unbind().click(() => {
      editPage(book, pageName);
      return false;
    });

    showPanel(".cs-readPanel");
  }

  function listPages(book) {
    redirectIfNeeded(book);

    $(".cs-bookTitle").text(book.name);
    $(".cs-deleteBookButton").toggle(book.canEdit);

    var ol = $(".cs-pageList").html("");
    book.listPages().forEach(function (name) {
      let a = $("<a>").attr("href", urlManager.fromBookAndPage(book,name)).text(name);
      ol.append($("<li>").append(a));
    });

    $(".cs-deleteBookButton").unbind().click(() => {
      const confirmed = confirm(`Are you sure you want to delete the book "${book.name}"?`);
      if (confirmed) {
        book.delete(auth.currentUser()).then(() => document.location.assign("#books"));
      }
      return false;
    });

    showPanel(".cs-listPagesPanel");
  }

  function listBooks() {
    Book.list(auth.currentUser()).then(books => {
      var ul = $(".cs-bookList").html("");
      $(".cs-noBooksAlert").toggle(books.length === 0);
      books.forEach(book => {
        let a = $("<a>").attr("href", urlManager.fromBookAndPage(book)).text(book.name);
        ul.append($("<li>").append(a));
      });
      showPanel(".cs-listBooksPanel");
    });
    $(".cs-newBookButton").unbind().click(() => {
      addBook();
      return false;
    });
  }

  function savePage(book, pageName) {
    // TODO: explicitly pass textarea value to savePage()
    let newText = $("textarea").val();

    if (newText === book.getPageText(pageName)) {
      showPage(book, pageName);
      return;
    }

    const hasText = book.setPageText(pageName,newText);

    // TODO: hide write latency?
    book.save(auth.currentUser())
    .then(() => {
      if (hasText) {
        showPage(book, pageName);
      } else {
        window.history.back();
        // listPages(book);
      }
    });
  }


  const auth = new Auth();
  auth.onChange((signedIn) => {
    $(".cs-signIn").toggle(signedIn === false);
    $(".cs-signOut").toggle(signedIn === true);
    $(".cs-signingIn").toggle(signedIn === undefined);
  });
  $(() => {
    $(".cs-signIn").click(() => {
      $('#cs-authModal').modal();
      auth.signIn();
      return false;
    });

    $(".cs-signOut").click(() => {
      auth.signOut();
      return false;
    });
  });

  auth.onChange((signedIn) => {
    $('#cs-authModal').modal('hide');
    if (signedIn != undefined) {
      if (signedIn) {
        Book.transferAnyLocalBooks(auth.currentUser());
      }
      router(listBooks, listPages, showPage);
    }
  });

});
