const makeId = require("./makeId.js");

class LocalStorage {
  // TODO: fix LocalStorage requires Book
  constructor(Book) {
    this.Book = Book;
    if (localStorage.bookList) {
      this.bookList = JSON.parse(localStorage.bookList);
    } else {
      this.bookList = [];
      this.buildBookList();
    }
  }

  pack(data) {
    var pagesStr = JSON.stringify(data.pages);
    const book = {
      name: data.name,
      pages: data.pages,
      pageCount: Object.keys(data.pages).length,
      size: pagesStr.length,
      id: data.id
    };
    return book;
  }

  unpack(data) {
    data.canEdit = true;
    return data;
  }

  load(id) {
    return new Promise((resolve, reject) => {
      var str = localStorage["book/"+id];
      if (!str) {
        reject(new Error(`Couldn't load book ${id}`));
      } else {
        resolve(this.unpack(JSON.parse(str)));
      }
    });
  }

  // TODO: Saving someone else's book creates a new book but doesn't redirect
  save(name, json) {
    return new Promise(resolve => {
      json.id = json.id || makeId();

      const book = this.pack(json);

      this.bookList = this.bookList.filter(bl => bl.id != json.id);

      this.bookList.push({
        name: book.name,
        created: book.created || firebase.firestore.Timestamp.now(),
        pageCount: book.pageCount,
        size: book.size,
        id: json.id
      });

      localStorage.bookList = JSON.stringify(this.bookList);
      localStorage["book/" + json.id] = JSON.stringify(book);
      resolve();
    });
  }

  getAllBooks() {
    return new Promise(resolve => {
      resolve(this.bookList);
    });
  }

  saveBookList() {
    localStorage.bookList = JSON.stringify(this.bookList);
  }

  deleteBook(json) {
    this.bookList = this.bookList.filter(bl => bl.id != json.id);
    delete localStorage["book/"+json.id];
    this.saveBookList();
    return new Promise(resolve => resolve());
  }

  deleteAllBooks() {
    this.bookList = [];
    this.saveBookList();
  }

  // TODO: remove buildBookList() once Jesse's books are imported?
  buildBookList() {
    console.log("Undeleting books...");
    Object.keys(localStorage)
    .filter(key => key.startsWith("book/"))
    .map(key => {
      let json = JSON.parse(localStorage[key]);
      json.pages = undefined;
      this.bookList.push(json);
    });
    this.saveBookList();
  }

}

module.exports = LocalStorage;
