const slugify = require('slugify');

class UrlManager {
  constructor() {

  }
  fromBookAndPage(book, pageName) {
    function encodeBookName(name) {
      return slugify(name.toLowerCase());
    }
    const encode = str => encodeURIComponent(str);
    let ret = `#books/${encode(book.id)}/${encodeBookName(book.name)}`;
    if (pageName) {
      ret += `/${encode(pageName.toLowerCase())}`;
    }
    return ret;
  }
  getUrlParts(url) {
    let [,command,id,book,slug] = /^.*#(?:\/?([^/]+)*)(?:\/?([^/]+)*)?(?:\/?([^/]+)*)(?:\/?([^/]+)*)?|/.exec(url);
    return {command,id,book:book&&decodeURIComponent(book),slug:slug&&decodeURIComponent(slug).toLowerCase()};
  }
}

module.exports = UrlManager;
