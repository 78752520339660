/* global firebaseui */

class Auth {
  constructor() {
    // Initialize the FirebaseUI Widget using Firebase.
    this.ui = new firebaseui.auth.AuthUI(firebase.auth());

    // FirebaseUI config.
    var uiConfig = {
      callbacks: { signInSuccessWithAuthResult: () => { this.loginPending = false; } },
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      signInFlow: "redirect",
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            prompt: 'select_account'
          }
        },
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          customParameters: {
            requireDisplayName: false
          }
        }
      ],
      tosUrl: '<your-tos-url>',
      privacyPolicyUrl: '<your-privacy-policy-url>'
    };

    this.uiConfig = uiConfig;

    this.loginPending = this.ui.isPendingRedirect();
    if (this.ui.isPendingRedirect()) {
      // Calling ui.start() here doesn't actually show the UI;
      // It clears the isPendingRedirect flag, and starts doing whatever
      // magic it does to process the actual federated login. When it's
      // done the signInSuccessWithAuthResult() callback will be called,
      // and then the onAuthStateChanged() callback
      this.signIn();
    }

  }

  onChange(fn) {
    firebase.auth().onAuthStateChanged(user => {
      let ret = !!user;
      if (this.loginPending) {
        ret = undefined;
      }
      fn(ret, user);
    });
  }

  signIn() {
    this.ui.start('#firebaseui-auth-container', this.uiConfig);
  }

  signOut() {
    firebase.auth().signOut();
  }

  currentUser() {
    return firebase.auth().currentUser;
  }
}

module.exports = Auth;
