// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript

const makeId = require('./makeId.js');

class Firestore {

  constructor() {
    this.db = firebase.firestore();

    // Disable deprecated features
    this.db.settings({
      timestampsInSnapshots: true
    });
  }

  pack(data) {
    var pagesStr = JSON.stringify(data.pages);
    const book = {
      name: data.name,
      pages: pagesStr,
      created:  firebase.firestore.FieldValue.serverTimestamp(),
      pageCount: Object.keys(data.pages).length,
      size: pagesStr.length,
      owner: firebase.auth().currentUser.uid,
      id: data.id
    };
    return book;
  }

  unpack(data) {
    let canEdit = false;
    const user = firebase.auth().currentUser;
    if (user) {
      canEdit = (data.owner === user.uid);
    }

    if (data.json) {
      console.log("Loading old format");
      const json = JSON.parse(data.json);
      json.canEdit = canEdit;
      return json;
    } else if (data.pages) {
      console.log("Loading new format");
      data.pages = JSON.parse(data.pages);
      data.canEdit = canEdit;
      return data;
    } else {
      throw(new Error("Can't read data"));
    }
  }

  load(id) {
    return new Promise((resolve,reject) => {
      this.db.collection("books").doc(id).get()
      .then(doc => {
        if (doc.exists) {
          const book = this.unpack(doc.data());
          resolve(book);
        } else {
          reject("Book doesn't exist");
        }
      })
      .catch(err => {
        reject(err);
      });
    });
  }

  // TODO: handle id collisions

  save(name, json) {
    return new Promise((resolve, reject) => {
      this.singleAttemptSave(name, json)
      .then(() => {
        resolve();
      })
      .catch(err => {

        if (err.code === "permission-denied") {

          // Retry the save with a different id
          // It's statistically impossible to get an id collision twice in a row
          // so if it fails a second time something else is wrong

          // singleAttemptSave() will give us a new id
          delete json.id;

          this.singleAttemptSave(name, json)
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
        } else reject(err);
      });
    });
  }

  singleAttemptSave(name, json) {
    const user = firebase.auth().currentUser;
    if (!user) {
      throw("Must be logged in to save");
    }

    json.id = json.id || makeId();
    json.name = name;
    const id = json.id;

    const book = this.pack(json);

    // Get a new write batch
    const batch = this.db.batch();

    // save the booklisting
    const bookListingRef = this.db.collection("booklistings").doc(id);
    batch.set(bookListingRef, {
      name: book.name,
      created: book.created,
      owner: book.owner,
      pageCount: book.pageCount,
      size: book.size
    });

    // save the book
    const bookRef = this.db.collection("books").doc(id);
    batch.set(bookRef, book);

    // Commit the batch
    return batch.commit();
  }

  deleteBook(json) {
    const batch = this.db.batch();

    // save the booklisting
    const bookListingRef = this.db.collection("booklistings").doc(json.id);
    batch.delete(bookListingRef);

    // save the book
    const bookRef = this.db.collection("books").doc(json.id);
    batch.delete(bookRef);

    // Commit the batch
    return batch.commit();
  }

  getAllBooks() {
    return new Promise((resolve) => {
      this.db.collection("booklistings")
        .where("owner", "==", firebase.auth().currentUser.uid)
          .get()
          .then((querySnapshot) => {
            let ret = [];

            // TODO: sort books by time created
            querySnapshot.forEach((doc) => {
              let booklisting = doc.data();
              booklisting.id = doc.id;
              ret.push(booklisting);
            });
            resolve(ret);
          });
        });
  }
}

module.exports = Firestore;
