const UrlManager = require("./UrlManager.js");
const LocalStorage = require("./LocalStorage.js");
const Firestore = require("./Firestore.js");

const localStorageDB = new LocalStorage(Book);
const firestoreDB = new Firestore(Book);

const urlManager = new UrlManager();

const startMessage = "";

class Book {
  constructor(id, name) {
    // TODO: handle confusion of having name here and in json.name
    this.id = id;
    this.name = name;
    this.loaded = false;

    this.json = {
      name: name,
      pages: { "start": startMessage }
    };
  }
  static list(currentUser) {
    const storage = currentUser ? firestoreDB : localStorageDB;
    return new Promise(resolve => {
      storage.getAllBooks().then(booklistings => {
        booklistings = booklistings.sort((a,b) => a.created < b.created ? 1 : -1);
        resolve(booklistings.map(l => new Book(l.id, l.name)));
      });

    });
  }
  setPageText(pageName, text) {
    if (!text) {
      delete this.json.pages[pageName];
      if (pageName === "start") {
        this.json.pages[pageName] = startMessage;
      }
    } else {
      this.json.pages[pageName] = text;
    }
    return this.json.pages[pageName] !== undefined;
  }
  getPageText(pageName) {
    return this.json.pages[pageName] || "";
  }
  textToHtml(text) {
    return text.replace(/\[([^\[\]]+)\](?:\((.*?)\))?/g, (m,description,title) => {
      title = title || description;
      return "<a href='"+urlManager.fromBookAndPage(this,title)+"'>"+description+"</a>";
    });
  }
  getPageHtml(pageName) {
    return this.textToHtml(this.getPageText(pageName));
  }
  getPageHeader(pageName) {
    if (!pageName || pageName === "start") {
      return this.name;
    }
    return pageName;
  }
  listPages() {
    return Object.keys(this.json.pages);
  }
  load() {
    return new Promise((resolve) => {
      this.loadWithStorage(localStorageDB)
      .then(resolve)
      .catch(() => {
        console.log("Loading from db");
        this.loadWithStorage(firestoreDB)
        .then(resolve);
      });
    });
  }

  delete(currentUser) {
    const storage = currentUser ? firestoreDB : localStorageDB;
    return storage.deleteBook(this.json);
  }

  loadWithStorage(storage) {

    return storage.load(this.id)
      .then(json => {
        this.id = json.id;
        this.name = json.name;
        this.json = json;
        this.loaded = true;
        this.canEdit = json.canEdit;
      });
  }

  save(currentUser) {
    const storage = currentUser ? firestoreDB : localStorageDB;
    return storage.save(this.name, this.json)
    .then(() => {
      this.id = this.json.id;
    });
  }

  static transferAnyLocalBooks(currentUser) {
    localStorageDB.getAllBooks().then(books => {
      books = books.map(booklisting => new Book(booklisting.id, booklisting.name));
      if (books.length) {
        localStorageDB.deleteAllBooks();
        console.log("Transferring books to server",books);
        books.forEach(book => {
          book.loadWithStorage(localStorageDB).then(() => book.save(currentUser));
        });
      }
    });
  }
}

module.exports = Book;
