function isMobileSafari() {
  return /iP(ad|hone|od).+Version\/[\d\.]+.*Safari/i.test(navigator.userAgent);
}

function enhanceTextarea(ta, cb) {
  let el = ta.get(0);

  // we need box-sizing: border-box, if the textarea has padding
  el.style.boxSizing = el.style.mozBoxSizing = 'border-box';

  // we don't need any scrollbars, do we? :)
  el.style.overflowY = 'hidden';

  let clone = el.cloneNode();
  $(clone).css({position:"absolute",width: el.scrollWidth, top: -1000}).attr("disabled", true);
  // $(clone).css({position:"absolute",top:-1000}).attr("disabled", true);
  el.parentNode.appendChild(clone);

  // the minimum height initiated through the "rows" attribute
  var minHeight = el.scrollHeight;

  // Adapted from http://bdadam.com/blog/automatically-adapting-the-height-textarea.html
  // Added a clone to fix scrolling weirdness
  function adjustHeight(textareaElement, minHeight) {
    clone.value = el.value;
    var outerHeight = parseInt(window.getComputedStyle(clone).height, 10);
    var diff = outerHeight - clone.clientHeight;

    // set the correct height
    // el.scrollHeight is the full height of the content, not just the visible part
    let newHeight = Math.max(minHeight, clone.scrollHeight + diff);
    // if (newHeight!=outerHeight)
    el.style.height = newHeight + 'px';
  }

  function getCursorY(el) {
    const pos = $(el).prop("selectionStart");
    clone.value = el.value.slice(0,pos);
    return clone.scrollHeight;
  }

  function scrollToCursor(el) {
    const elY = getCursorY(el);
    const pageY = elY + $(el).offset().top;
    const screenY = pageY - window.window.scrollY;

    if (screenY < 50 || screenY > window.innerHeight/2.5) {
      window.scrollTo(window.scrollX, $(el).offset().top + getCursorY(el) - window.innerHeight/3);
    }
  }


  ta
  .unbind()
  .on("input propertychange resize", function() {
    adjustHeight(el, minHeight);
  })
  .on("keydown", function(e) {
    if ((e.keyCode === 13 && e.shiftKey) // shift+return
      ||
      (e.key === "s" && e.metaKey) // cmd+s
      ||
      (e.key === "s" && e.ctrlKey)) // ctrl+s
    {
      cb;
      e.preventDefault();
      return true;
    }
  })
  .on("blur", () => {
    // savePage(book, pageName);
  });

  if (isMobileSafari()) {
    ta
    .on("keyup click propertychange", function () {
      scrollToCursor(el, minHeight);
    });
  }

    // we adjust height to the initial content
    adjustHeight(el, minHeight);
  ta.focus();
}


module.exports = enhanceTextarea;
